import "./bootstrap";
import "../css/app.css";

import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { Link, Head } from "@inertiajs/inertia-vue3";
import Alpine from "alpinejs";
import VueMeta from "vue-meta";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

window.Alpine = Alpine;
Alpine.start();

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, app, props, plugin }) {
        const captchaKey = props.initialPage.props.recaptcha_site_key;
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueReCaptcha, { siteKey: captchaKey,loaderOptions: {useRecaptchaNet: true}})
            .component("Link", Link)
            .component("Head", Head)
            .component("QuillEditor", QuillEditor)
            .mount(el);
    },
});

InertiaProgress.init({
    color: "#29d",
    showSpinner: true,
});
